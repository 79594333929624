import logo from './girls_club.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p style={{width: "660px", marginTop: "-15px", marginBottom: "30px"}}>
          Girls Club Teesside was set up to create opportunities for local ladies to try new
          activities in a friendly and safe environment. By all coming in as newbies, we all know
          what each other is feeling and it doesn't feel quite as daunting.
        </p>
        <a
          className="App-link"
          href="https://www.instagram.com/girls_club_teesside/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram
        </a>
        <a
          className="App-link"
          href="https://www.facebook.com/groups/360266296904255/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook Group
        </a>
        <p style={{"font-weight": 'bold'}}>
          girls-club-teesside@hotmail.com
        </p>

      </header>
    </div>
  );
}

export default App;
